import React from 'react';
import { graphql, PageProps } from 'gatsby';
import 'twin.macro';
import BaseLayout from '../../layouts/base';
import { GetPendirianPtPadiUmkmDataQuery } from '../../graphql-types';

import Package, { PackageLeftContent, Product } from '../../components/package';
import Partner from '../../components/partner';
import Faq, { FaqContent } from '../../components/faq';
import Article from '../../components/article';
import HowTo from '../../components/howto';
import { BtnPrimary } from '../../components/scaffolds';
import Jumbotron from '../../components/jumbotron';
import { Helmet } from 'react-helmet';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Testimonials from '../../components/testimonials';

interface PageState {
  wa_id?: number;
}

class PendirianPTPadiUmkm extends React.Component<
  PageProps<GetPendirianPtPadiUmkmDataQuery>,
  PageState
> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      wa_id: 1,
    };
    this.getWaId();
  }

  jumbotron = this.props.data?.jumbotron?.childrenImageSharp[0]?.fluid;
  logo = this.props.data?.logo?.childrenImageSharp[0]?.fluid;
  additionalLogo = this.props.data?.additionalLogo?.childrenImageSharp[0]?.fluid;

  // SEO data
  siteMeta = this.props.data?.site?.siteMetadata;
  canonical = `${this.siteMeta.url}${this.props.location.pathname}`;

  waNumbers = [
    {
      id: 1,
      value: '6281617369369',
    },
    {
      id: 2,
      value: '6281299667071',
    },
  ];

  get whatsappLink() {
    let phone = this.waNumbers.find(f => f.id === this.state?.wa_id);
    const num = phone?.value || this.waNumbers[0].value;
    return `https://wa.me/${num}?text=Halo, saya mengetahui Easybiz dari PaDi UMKM. Saya ingin memesan paket Pendirian PT dengan diskon spesial PaDi UMKM`;
  }

  async onWhatsappClicked() {
    this.setState({
      wa_id: this.state?.wa_id + 1,
    });

    if (this.state?.wa_id >= 2) {
      this.setState({
        wa_id: 1,
      });
    }

    let phone = this.waNumbers.find(f => f.id === this.state?.wa_id);
    let body = {
      value: phone?.id || 1,
    };

    try {
      if (typeof window !== 'undefined') {
        await window.fetch(`${this.siteMeta.url}/api/whatsapp`, {
          method: 'POST',
          body: JSON.stringify(body),
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getWaId() {
    try {
      if (typeof window !== 'undefined') {
        const response = await window.fetch(`${this.siteMeta.url}/api/whatsapp`, { method: 'GET' });
        const wa = await response.json();

        this.setState({
          wa_id: wa?.value || 1,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  leftContents: PackageLeftContent[] = [
    {
      title: 'Pengecekkan Nama PT',
      detail: null,
    },
    {
      title: 'Akta Perusahaan',
      detail: null,
    },
    {
      title: 'SK Kemenkumham',
      detail: null,
    },
    {
      title: 'NPWP Perusahaan',
      detail:
        'Hanya untuk perusahaan yang berdomisili di DKI Jakarta. Untuk perusahaan di luar DKI Jakarta pengajuan dilakukan pelanggan di KPP setempat. Easybiz memandu proses pengisian dan pengajuan form',
    },
    {
      title: 'Tambahan BNRI (Wajib)',
      detail:
        'Setiap akta pendirian PT wajib diumumkan di tambahan Berita Negara Republik Indonesia (BNRI). Tanpa itu pendirian PT belum sah dan belum mengikat pihak ketiga',
    },
    {
      title: 'Pembukaan Bank UOB',
      detail: 'Anda akan mendapatkan rekening UOB atas nama perusahaan Anda',
    },
    {
      title: 'Aplikasi Bizsmart',
      detail:
        'Bank UOB mengeluarkan aplikasi Bizsmart sebagai solusi digital penunjang bisnis Anda yang dapat mengelola beberapa proses bisnis',
    },
    {
      title: 'Virtual Office 1 Tahun',
      detail:
        "Termasuk ruang meeting, nomor telepon bersama, dan alamat surat menyurat.'Hubungi sales' untuk informasi lebih lengkap.",
    },
    {
      title: 'Nomor Induk Berusaha',
      detail:
        'Identitas pelaku usaha dalam rangka pelaksanaan kegiatan berusaha yang diterbitkan oleh Lembaga OSS',
    },
    {
      title: 'Surat Izin Usaha Perdagangan',
      detail:
        'Izin usaha bagi semua perusahaan yang melakukan kegiatan usaha perdagangan yang diterbitkan oleh Lembaga OSS',
    },
  ];

  products: Product[] = [
    {
      title: 'Paket Basic',
      subtitle: {
        text: '',
        items: [
          {
            list: '✔',
            text: 'Berlaku untuk seluruh Indonesia',
          },
          {
            list: '✘',
            text: 'Tanpa Virtual Office',
          },
          {
            list: '✘',
            text: 'Tidak termasuk NPWP perusahaan',
          },
        ],
      },
      estimation: (
        <div>
          Estimasi waktu pengerjaan: <br /> 10 hari kerja
        </div>
      ),
      price: '8500000',
      specialPrice: '7500000',
      link: this.whatsappLink,
      onLinkClicked: async () => await this.onWhatsappClicked(),
      ribbon: null,
      tAC: true,
    },
    {
      title: 'Paket Easy Business',
      subtitle: {
        text: '',
        items: [
          {
            list: '✔',
            text: 'PT khusus wilayah Jakarta',
          },
          {
            list: '✔',
            text: 'Virtual Office untuk area Jakarta',
          },
          {
            list: '✔',
            text: 'Termasuk NPWP perusahaan',
          },
        ],
      },
      estimation: (
        <div>
          Estimasi waktu pengerjaan: <br /> 10 hari kerja
        </div>
      ),
      price: '9800000',
      specialPrice: '8700000',
      link: this.whatsappLink,
      onLinkClicked: async () => await this.onWhatsappClicked(),
      tAC: true,
    },
  ];

  faqs: FaqContent[] = [
    {
      title: 'Apa saja dokumen persyaratan untuk mendirikan PT?',
      detail: {
        text: 'Para pendiri dan pengurus perusahaan:',
        items: [
          {
            text: 'KTP (e-KTP)',
            items: [],
          },
          {
            text: 'Kartu Keluarga',
            items: [],
          },
          {
            text: 'NPWP <br /> Note:',
            items: [
              {
                text: 'Mohon dipastikan alamat yang tercantum pada KTP dan NPWP sama dan valid',
              },
              {
                text:
                  'NPWP para pendiri dan pengurus sudah menggunakan format terupdate (terdapat NIK pada NPWP)',
              },
              {
                text: 'Tidak memiliki laporan pajak terhutang',
              },
            ],
          },
        ],
      },
    },
    {
      title:
        'Apakah ada perbedaan syarat pendirian PT sebelum dan sesudah berlakunya Undang-Undang Cipta Kerja?',
      detail: {
        text:
          'Terdapat beberapa perbedaan, di antaranya adalah mengenai cara memperoleh status badan hukum PT, besaran minimal modal dasar PT, pendirian PT untuk usaha mikro dan kecil yang bisa dilakukan oleh satu orang saja, dan dihapusnya kewajiban memiliki TDP. Namun untuk detail dan prakteknya saat ini pemerintah masih menyusun sejumlah peraturan pelaksana dari Undang-Undang Cipta Kerja',
        items: [],
      },
    },
    {
      title: 'Berapa jumlah minimal pemegang saham dari sebuah PT?',
      detail: {
        text:
          'Saat ini komposisi pemegang saham PT wajib terdiri dari minimal dua orang. Akan tetapi, setelah berlakunya Undang-Undang Cipta Kerja terdapat sedikit perubahan yang membolehkan PT yang memenuhi kriteria usaha mikro dan kecil untuk memiliki satu orang pemegang saham. Namun ketentuan ini masih menunggu peraturan pelaksana dari Undang-Undang Cipta Kerja agar dapat berjalan dengan efektif.',
        items: [],
      },
    },
  ];

  article1 = this.props.data?.article1?.childrenImageSharp[0]?.fluid;
  article2 = this.props.data?.article2?.childrenImageSharp[0]?.fluid;
  article3 = this.props.data?.article3?.childrenImageSharp[0]?.fluid;
  article4 = this.props.data?.article4?.childrenImageSharp[0]?.fluid;
  articleContents = [
    {
      title: 'Pendirian PT di Tahun 2021 berdasarkan UU Cipta Kerja',
      date: '01 Jan 2021',
      info: 'Bacaan 7 menit',
      detail:
        'Prosedur dan syarat terbaru pendirian PT di tahun 2021 dipermudah untuk memberbaiki ekosistem investasi; kemudahan, perlindungan, dan pemberdayaan UMKM; serta kemudahan berusaha pada umumnya. Apa saja poin-poin penting yang wajib kamu ketahui?',
      img: this.article1,
      link: 'pendirian-pt-di-tahun-2021-berdasarkan-undang-undang-cipta-kerja/',
    },
    {
      title: 'Prosedur dan Syarat Pendirian PT Terbaru Yang Wajib Diketahui',
      date: '18 Februari 2020',
      info: 'Bacaan 15 menit',
      detail:
        'Dalam kurun waktu 2 tahun terakhir cukup banyak terjadi perubahan pada prosedur dan syarat pendirian PT (Perseroan Terbatas) terutama yang terkait dengan pengurusan izin usahanya. Perubahan yang signifikan terkait dengan prosedur dan syarat pendirian PT dimulai dengan berlakunya Online Single Submission (OSS) pada tahun 2018. OSS adalah proses perizinan berusaha terintegrasi secara elektronik. OSS mengintrodusir adanya  Nomor Induk Berusaha (NIB), penyesuaian maksud dan tujuan dengan kegiatan usaha menggunakan Klasifikasi Baku Lapangan Usaha Indonesia (KBLI) 2017 dan cara pengajuan izin usaha dan izin operasional atau izin komersial.',
      img: this.article2,
      link: 'prosedur-dan-syarat-pendirian-pt-terbaru-yang-wajib-diketahui/',
    },
    {
      title: 'Prosedur Terbaru Mendirikan PT atau CV di Tahun 2020',
      date: '15 Jan 2020',
      info: 'Bacaan 8 menit',
      detail:
        'Sejak berlakunya pengurusan perizinan usaha yang terintegrasi melalui platform Online Single Submission (OSS), pemerintah menindaklanjutinya dengan membuat sejumlah aturan dan kebijakan terbaru. Ini aturan terbaru seputar prosedur pendirian PT atau CV serta proses perizinan usahanya melalui OSS yang wajib kamu ketahui.',
      img: this.article3,
      link:
        'begini-prosedur-dan-syarat-mendapatkan-izin-operasional-lembaga-kursus-dan-pelatihan-lkp/',
    },
    {
      title: 'Ini Patut Diperhatikan Jika Kamu Ingin Mendirikan PT yang Non-Profit',
      date: '04 Sep 2020',
      info: 'Bacaan 6 menit',
      detail:
        'Sebagai badan hukum yang didirikan berdasarkan perjanjian sebagaimana ditegaskan Undang-Undang Nomor 40 Tahun 2007 tentang Perseroan Terbatas (“UU PT”), sebuah PT memiliki maksud dan tujuan pendirian dan kegiatan usaha berdasarkan kehendak para pendirinya. Kehendak bebas dari para pendiri ini merupakan salah satu cerminan asas kebebasan berkontrak dalam Pasal 1338 Kitab Undang-Undang Hukum Perdata (“KUH Perdata”). Namun, apakah boleh jika para founder berkehendak mendirikan PT yang bersifat non-profit?',
      img: this.article4,
      link: 'ini-patut-diperhatikan-jika-kamu-ingin-mendirikan-pt-yang-non-profit/',
    },
  ];

  jsonLd = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'WebSite',
        '@id': 'https://www.easybiz.id/#website',
        url: 'https://www.easybiz.id/',
        name: 'Easybiz',
        description: 'Easybiz.id',
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: 'https://www.easybiz.id/?s={search_term_string}',
            'query-input': 'required name=search_term_string',
          },
        ],
        inLanguage: 'en-US',
      },
      {
        '@type': 'WebPage',
        '@id': 'https://www.easybiz.id/layanan/paket-pendirian-pt-padi-umkm/#webpage',
        url: 'https://www.easybiz.id/layanan/paket-pendirian-pt-padi-umkm/',
        name: 'Paket Easy Business - Easybiz',
        isPartOf: { '@id': 'https://www.easybiz.id/#website' },
        datePublished: '2020-09-05T05:42:40+00:00',
        dateModified: '2021-02-25T08:36:09+00:00',
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: ['https://www.easybiz.id/layanan/paket-pendirian-pt-padi-umkm/'],
          },
        ],
      },
    ],
  };

  render() {
    return (
      <BaseLayout
        logo={this.logo}
        pathname={this.props.location.pathname}
        hideHeader={true}
        whatsappLink={this.whatsappLink}
        additionalLogo={this.additionalLogo}
      >
        <Helmet>
          <title>Paket Pendirian PT x PaDi UMKM</title>
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <link rel="canonical" href={this.canonical} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Paket Pendirian PT x PaDi UMKM" />
          <meta
            property="og:url"
            content="https://www.easybiz.id/layanan/paket-pendirian-pt-padi-umkm/"
          />
          <meta property="og:site_name" content="Easybiz" />
          <meta property="article:modified_time" content="2021-02-25T08:36:09+00:00" />
          <meta name="twitter:card" content="summary_large_image" />
          <script type="application/ld+json">{JSON.stringify(this.jsonLd, undefined, 4)}</script>
        </Helmet>
        <Jumbotron
          title="Pendirian PT"
          detail="Solusi pendirian PT paling lengkap. Termasuk dokumen legalitas dan penggunaan alamat Virtual Office. Gratis konsultasi."
          action={
            <div tw="lg:flex items-center">
              <BtnPrimary
                className="gtm-call-button"
                href={this.whatsappLink}
                target="__blank"
                tw="mr-4 mb-6 lg:mb-0"
                onClick={async () => await this.onWhatsappClicked()}
              >
                Hubungi Kami Disini
              </BtnPrimary>
              <h4 tw="m-0 text-yellow-bright hover:text-blue-lapis underline">
                <AnchorLink
                  className="gtm-view-offer"
                  tw="text-yellow-bright cursor-pointer"
                  to="/layanan/paket-pendirian-pt-padi-umkm#product"
                >
                  Lihat Penawaran Kami
                </AnchorLink>
              </h4>
            </div>
          }
          img={this.jumbotron}
          curved={false}
          hideHeader={true}
        ></Jumbotron>
        <Partner></Partner>
        <Package
          title={
            <div>
              <b tw="text-yellow-bright dark:text-orange">Pilih Paket</b> Pendirian PT yang Sesuai
              dengan Kebutuhan Anda
            </div>
          }
          leftSubtitle="Yang akan Anda dapatkan dalam paket pendirian PT:"
          leftContents={this.leftContents}
          products={this.products}
          notes="*Harga di atas belum termasuk PPN 10%"
        ></Package>
        <HowTo
          title={
            <div>
              <b tw="text-blue-lapis dark:text-yellow-bright">Pendirian PT</b> dengan Mudah Bersama
              Easybiz
            </div>
          }
          subtitle="Bagaimana cara Easybiz membantu Anda mendirikan PT"
          howToLink={this.whatsappLink}
          onHowToLinkClicked={async () => await this.onWhatsappClicked()}
        ></HowTo>
        <Faq
          title={
            <div>
              <b tw="text-orange">FAQ</b> Pendirian PT
            </div>
          }
          subtitle="Pertanyaan yang sering ditanyakan oleh calon pendiri PT terkait paket pendirian PT."
          faqs={this.faqs}
        ></Faq>
        <Testimonials withoutHeader={true} revertColor={true}>
          <h2 tw="text-black-100 dark:text-white pb-2">
            Jadikan <b tw="text-blue-lapis dark:text-orange">Easybiz Partner Anda</b> dalam Memulai
            Bisnis
          </h2>
          <div tw="grid grid-cols-1 lg:grid-cols-3 lg:gap-4 mx-0 lg:mx-32 py-4 text-left">
            <div tw="bg-black-500 rounded-2xl py-0 px-3 lg:px-5 my-2">
              <h2 tw="text-black-100 py-4 m-0">2000+</h2>
              <p tw="text-b1 text-black-100">Klien sukses mendirikan perusahaan dan perizinan</p>
            </div>
            <div tw="bg-black-500 rounded-2xl py-0 px-3 lg:px-5 my-2">
              <h2 tw="text-black-100 py-4 m-0">6 tahun</h2>
              <p tw="text-b1 text-black-100">Pengalaman Easybiz di bidang pendirian perusahaan</p>
            </div>
            <div tw="bg-black-500 rounded-2xl py-0 px-3 lg:px-5 my-2">
              <h2 tw="text-black-100 py-4 m-0">4.5/5</h2>
              <p tw="text-b1 text-black-100">Review kepuasan layanan Easybiz</p>
            </div>
          </div>
          <div tw="text-center w-full pt-4 pb-2">
            <p tw="text-h4 mb-3 font-extrabold font-gilroy dark:text-white">
              Testimonial dari klien puas Easybiz:
            </p>
          </div>
        </Testimonials>
        <Article contents={this.articleContents}></Article>
      </BaseLayout>
    );
  }
}

export default PendirianPTPadiUmkm;

export const query = graphql`
  query getPendirianPTPadiUmkmData {
    jumbotron: file(relativePath: { eq: "images/jumbotron/pendirian-pt.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 1440) {
          ...FileImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "images/logo.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 130) {
          ...FileImageSharpFluid
        }
      }
    }
    additionalLogo: file(relativePath: { eq: "images/logo-umkm.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 90) {
          ...FileImageSharpFluid
        }
      }
    }
    article1: file(relativePath: { eq: "images/article/pendirian-pt/1.jpg" }) {
      childrenImageSharp {
        fluid(maxWidth: 124) {
          ...FileImageSharpFluid
        }
      }
    }
    article2: file(relativePath: { eq: "images/article/pendirian-pt/2.jpg" }) {
      childrenImageSharp {
        fluid(maxWidth: 124) {
          ...FileImageSharpFluid
        }
      }
    }
    article3: file(relativePath: { eq: "images/article/pendirian-pt/3.jpg" }) {
      childrenImageSharp {
        fluid(maxWidth: 124) {
          ...FileImageSharpFluid
        }
      }
    }
    article4: file(relativePath: { eq: "images/article/pendirian-pt/4.jpg" }) {
      childrenImageSharp {
        fluid(maxWidth: 124) {
          ...FileImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        ...SiteMetadataFields
      }
    }
  }
`;
